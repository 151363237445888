import React, {
  useState,
  useMemo,
  useLayoutEffect,
  useRef,
  useEffect,
} from 'react';
import { useIntl } from 'context/IntlContext';
import { useProducts } from 'context/SavingProductsContext';
import * as d3 from 'd3';
import { Text, Box } from 'theme-ui';

import {
  ChartSVG,
  LegendBlock,
  LegendContainer,
  AccountInfo,
  LegendValue,
  LegendColor,
  MouseDetectionArea,
  AxisText,
  InitialFundsContainer,
  SlideInput,
  SliderAndLegendContainer,
} from './styled';

const AxisLabels = ({ layout, mouseX, xScale, t }) => {
  const { height, width, margins } = layout;
  const verticalPlacement = height + 27;
  const years = Math.floor(xScale.invert(mouseX - layout.margins.left) / 12);

  const labelHide = 80;
  let movingLabelAlignment = 'middle';
  if (mouseX < margins.left + labelHide) {
    movingLabelAlignment = 'start';
  } else if (mouseX > width - labelHide) {
    movingLabelAlignment = 'end';
  }

  let movingLabel = t('homepage.offer.calculator.chart.chart_legend.mid', {
    term: years,
  });

  return (
    <g>
      <AxisText
        x={mouseX}
        y={verticalPlacement}
        textAnchor={movingLabelAlignment}
      >
        {movingLabel}
      </AxisText>
    </g>
  );
};

const Legend = ({ product, months, deposit, t }) => {
  const {
    productName,
    interestRate,
    calculatorColor: { css },
  } = product;
  const { formatMoney } = useIntl();
  return (
    <LegendBlock>
      <LegendColor sqcolor={css} />
      <AccountInfo>
        <div>{interestRate}</div>
        <div>%</div>
        <div>{t(`${productName}`)}</div>
      </AccountInfo>
      <LegendValue>{formatMoney(product.formula(deposit)(months))}</LegendValue>
    </LegendBlock>
  );
};

const ProductPath = ({ product, generator, values }) => {
  const {
    productName,
    calculatorColor: { css },
  } = product;
  const area = generator(values);
  return <path key={productName} fill={css} d={area} />;
};

const PathDots = ({ mouseX, rates, mouseMonths, yScale, width }) => {
  const minY = rates.reduce(
    (min = 0, el) => Math.min(min, yScale(el(mouseMonths))),
    1000,
  );
  const maxY = rates.reduce(
    (max = 0, el) => Math.max(max, yScale(el(mouseMonths))),
    0,
  );
  return (
    <g>
      <line x1={mouseX} x2={mouseX} y1={minY} y2={maxY} stroke="#000" />
      {rates.map((el, k) => (
        <circle
          key={`shadow_${k}`}
          cx={mouseX}
          cy={yScale(el(mouseMonths))}
          r={7}
          filter="url(#blurMe)"
          fill="rgba(0,0,0,0.5)"
        />
      ))}
      {rates.map((el, k) => (
        <circle
          key={`dot_${k}`}
          cx={mouseX}
          cy={yScale(el(mouseMonths))}
          r={7}
          stroke="#eee"
          strokeWidth="1"
          fill="#fafafa"
        />
      ))}
    </g>
  );
};

const useChartMousePosition = ({ startPosition, marginOffset, scale }) => {
  const [mouseX, setMouseX] = useState(startPosition);
  const scaleValue = scale.invert(mouseX - marginOffset);
  return [mouseX, scaleValue, setMouseX];
};

const Slider = ({ val, setter }) => {
  const change = (e) => {
    const v = e.target.value;
    setter(v === '0' ? 1 : v);
  };
  return (
    <SlideInput
      type="range"
      min={0}
      step={100}
      max={250000}
      value={val}
      onChange={change}
    />
  );
};

const useDimensions = () => {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({});
  const [resizeTrigger, setResize] = useState(0);
  useLayoutEffect(() => {
    const a = ref.current.getBoundingClientRect();
    setDimensions(a);
  }, [resizeTrigger]);

  useEffect(() => {
    const handleResize = () => {
      setResize((a) => a + 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [ref, dimensions];
};

const calcCompound = (interestRate) => (firstInvestment) => (months) =>
  firstInvestment * (1 + parseFloat(interestRate) / 100) ** (months / 12);

const CalculatorChart = ({ timeMaxYears, deposit }) => {
  const { productList: savingProductsList } = useProducts();
  const [initialDeposit, setInitialDeposit] = useState(deposit);
  const [products, setProducts] = useState<any>([]);
  const [graphValues, setGraphValues] = useState<any>([]);
  const { t, formatMoneyShort } = useIntl();
  const [ref, dimensions] = useDimensions();

  const fullWidth = dimensions.width || 320;
  const fullHeight = dimensions.height || 300;

  const margins = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 40,
  };

  const width = fullWidth - margins.left - margins.right;
  const height = fullHeight - margins.top - margins.bottom;

  const layout = {
    width,
    height,
    margins,
  };

  useEffect(() => {
    let isMounted = true;

    if (!isMounted || !savingProductsList || savingProductsList?.length === 0)
      return;

    const products = savingProductsList
      .filter((p) => p.compoundInterest)
      .map((p) => ({ formula: calcCompound(p.interestRate), ...p }));

    setProducts(products);

    const graphValues = products.map((product) => {
      const interest = product.formula(1);
      const data = Array.from(
        { length: timeMaxYears * 12 + 1 },
        (v, i) => i,
      ).map((v) => ({
        month: v,
        value: interest(v),
      }));
      return [product, data];
    });

    setGraphValues(graphValues);

    return () => {
      isMounted = false;
    };
  }, [timeMaxYears, savingProductsList]);

  const graphScale = useMemo(() => {
    if (!timeMaxYears || !width) return;
    return d3
      .scalePow()
      .exponent(0.75)
      .domain([0, timeMaxYears * 12])
      .range([0, width]);
  }, [timeMaxYears, width]);

  const timeScale = useMemo(() => {
    if (!timeMaxYears || !width) return;
    return d3
      .scaleLinear()
      .domain([0, timeMaxYears * 12])
      .range([0, width]);
  }, [timeMaxYears, width]);

  const moneyScale = useMemo(() => {
    if (!height || !graphValues || graphValues.length === 0) return;
    return d3
      .scaleLinear()
      .domain([
        1,
        d3.max(
          graphValues.length >= 3 ? graphValues[2][1] : 0,
          (d) => +d?.value,
        ) * 1.05,
      ])
      .range([height, 0]);
  }, [height, graphValues]);

  const areaGraph = useMemo(() => {
    if (!timeScale || !moneyScale) return;
    return d3
      .area()
      .x((d) => graphScale(d.month))
      .y0(moneyScale(1))
      .y1((d) => moneyScale(d.value));
  }, [timeScale, moneyScale]);

  const [mouseX, mouseValue, setMouseX] = useChartMousePosition({
    startPosition: fullWidth / 2,
    marginOffset: margins.left,
    scale: timeScale,
  });

  const mouseMove = ({ nativeEvent }) => {
    setMouseX(nativeEvent.offsetX);
  };

  const touchMove = (e) => {
    e.preventDefault();
    const max = Math.ceil(dimensions.width - margins.right);
    const min = margins.left;
    const v = e.nativeEvent.touches[0].pageX - dimensions.left;
    let trimedV = v < min ? min : v;
    trimedV = trimedV > max ? max : trimedV;
    setMouseX(trimedV);
  };

  return (
    <div style={{ zIndex: 3 }}>
      <Box
        sx={{
          px: 4,
          bg: 'secondaryBackground',
          textAlign: ['initial', 'initial', 'initial', 'initial', 'center'],
        }}
      >
        <SliderAndLegendContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            }}
          >
            <InitialFundsContainer>
              <div>{t('home.calculator.input.placeholder')}</div>
              <div>{formatMoneyShort(initialDeposit)}</div>
            </InitialFundsContainer>
            <Text
              sx={{
                display: 'block',
                fontSize: 2,
                color: 'senary',
                pt: 4,
                pb: 8,
                opacity: '0.5',
              }}
            >
              {t('homepage.offer.calculator.slider.initial_funds')}
            </Text>
          </Box>
          <Slider val={initialDeposit} setter={setInitialDeposit} />
          <LegendContainer>
            {products.map((p) => (
              <Legend
                key={p.productName}
                product={p}
                deposit={initialDeposit}
                months={mouseValue}
                t={t}
              />
            ))}
          </LegendContainer>
        </SliderAndLegendContainer>
        <div style={{ zIndex: 10 }}>
          <ChartSVG width="100%" height={fullHeight} ref={ref}>
            <defs>
              <clipPath id="areaClip">
                <rect width={width} height={height} x="0" y="0" />
              </clipPath>
              <filter id="blurMe" width="200%" height="200%" x="-50%" y="-50%">
                <feGaussianBlur in="SourceGraphic" stdDeviation="2.5" />
              </filter>
            </defs>
            <g
              clipPath="url(#areaClip)"
              transform={`translate(${margins.left}, ${margins.top})`}
            >
              {graphValues?.map((d) => (
                <ProductPath
                  key={d[0].productName}
                  product={d[0]}
                  generator={areaGraph}
                  values={d[1]}
                />
              ))}
            </g>
            {timeScale && t && (
              <AxisLabels
                layout={layout}
                mouseX={mouseX}
                xScale={timeScale}
                t={t}
              />
            )}
            {moneyScale && (
              <PathDots
                mouseX={mouseX}
                mouseMonths={mouseValue}
                width={fullWidth}
                rates={products.map((p) => p.formula(1))}
                yScale={moneyScale}
              />
            )}
            <MouseDetectionArea
              width={width}
              height={fullHeight}
              x={margins.left}
              y={0}
              onMouseMove={mouseMove}
              onTouchMove={touchMove}
            />
          </ChartSVG>
        </div>
        <Box
          sx={{
            px: 3,
            display: 'flex',
            justifyContent: 'space-between',
            mt: ['-32px', '-32px', '-32px', '-32px', '-32px'],
          }}
        >
          <Text
            variant="smallReferences"
            sx={{
              display: 'block',
              color: '#293040',
              fontSize: 0,
              opacity: '0.5',
            }}
          >
            {t('homepage.offer.calculator.chart.chart_legend.min')}
          </Text>
          <Text
            variant="smallReferences"
            sx={{
              display: 'block',
              color: '#293040',
              fontSize: 0,
              opacity: '0.5',
            }}
          >
            {t('homepage.offer.calculator.chart.chart_legend.max')}
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default CalculatorChart;
