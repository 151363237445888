import React, { useEffect, useState } from 'react';
import { Box, Text } from 'theme-ui';
import { clearInterval } from 'timers';

interface ToastProps {
  type: 'success' | 'warning' | 'error';
  message: string;
  show: boolean;
  duration: number;
  onClose: Function;
}

const Toast = ({ message, type, show, duration, onClose }: ToastProps) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onClose();
      }, duration);
    }
  }, [show]);

  function getBackgroundColor() {
    switch (type) {
      case 'success':
        return 'success';
      case 'warning':
        return 'septenary';
      default:
        return 'error';
    }
  }

  return (
    <Box
      sx={{
        zIndex: 9999,
        position: 'absolute',
        bottom: '1em',
        left: '1em',
        right: '1em',
        display: show ? 'block' : 'none',
        backgroundColor: getBackgroundColor(),
        padding: 5,
        color: 'lightNeutral',
        fontSize: 3,
        borderRadius: '8px',
        boxShadow: '0 2px 2px 2px rgba(0, 0, 0, 0.2)',
        width: '80%',
        alignSelf: 'center',
        margin: 'auto',
        textAlign: 'center'
      }}
    >
      <Text>{message}</Text>
    </Box>
  );
};

export default Toast;
