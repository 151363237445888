import React from 'react';
import Modal from 'react-modal';
import { Box } from 'theme-ui';

function isPortraitMode() {
  const screenWidth =
    typeof window !== 'undefined' ? window.innerWidth : undefined;
  const screenHeight =
    typeof window !== 'undefined' ? window.innerHeight : undefined;

  if (!screenHeight || !screenWidth) return false;

  return screenHeight > screenWidth ? true : false;
}

Modal.setAppElement('#___gatsby');

interface BaseModalProps {
  closeModal: () => void;
  afterOpen?: () => void;
  afterClose?: () => void;
  modalIsOpen: boolean;
  children?: any;
}

const BaseModal = ({ closeModal, afterOpen, afterClose, modalIsOpen, children }: BaseModalProps) => {

  const customStyles = {
    content: {
      top: isPortraitMode() ? '450px' : '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      maxWidth: '600px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderWidth: '0px',
      zIndex: '99',
      overflow: 'visible',
    },
    overlay: {
      backgroundColor: 'rgba(40, 39, 37, 0.5',
      overflow: 'auto',
      top: '0',
      right: '0',
      left: '0',
      bottom: '0',
      height: '100%',
      width: '100%',
      zIndex: '98',
    },
  };

  function handleCloseModal() {
    closeModal();
  }

  function handleOnAfterOpen() {
    document.body.style.overflow = 'hidden';
    if(afterOpen) afterOpen();

  }

  function handleOnAfterClose() {
    document.body.style.overflow = 'unset';
    if(afterClose) afterClose();
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      style={customStyles}
      onAfterOpen={handleOnAfterOpen}
      onAfterClose={handleOnAfterClose}
    >
      <Box
        sx={{
          borderRadius: '16px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          pt: [7, 7, 7, 7, 7],
          pb: [7, 7, 7, 7, 7],
          pr: [7, 7, 7, 7, 8],
          pl: [7, 7, 7, 7, 8],
          mr: [2, 2, 2, 2, 0],
          ml: [2, 2, 2, 2, 0],
          mb: [3, 3, , 3, 3, 0],
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default BaseModal;
