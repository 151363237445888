import React, { FC } from 'react';

import { Box, Text, Image } from 'theme-ui';

type Props = {
  product?: object;
  index?: number;
  totalProducts: number;
};

const Card: FC<Props> = ({ product, index, totalProducts }: Props) => {
  return (
    <Box
      sx={{
        flex: '0 0 auto',
        maxWidth: ['276px', '276px', '276px', '276px', '320px'],
        display: 'flex',
        flexDirection: ['row', 'row', 'row', 'row', 'column'],
        borderRadius: 2,
        px: 8,
        ml: index === 0 ? '0' : [1, 1, 1, 1, 5],
        mr: index === totalProducts - 1 ? '0px' : [1, 1, 1, 1, 5],
        alignItems: 'flex-start',
        backgroundColor: 'primaryBackground',
      }}
    >
      <Box sx={{ flex: '1' }}>
        <Text
          sx={{
            display: 'block',
            fontSize: 4,
            color: 'darkNeutral',
            p: 5,
            pl: [5, 5, 5, 5, null],
            textAlign: 'center',
            borderBottom: '2px solid #EBF5EC',
          }}
        >
          {product.productName}
        </Text>
        <Text
          sx={{
            display: 'block',
            fontWeight: '700',
            fontSize: '72px',
            color: 'primary',
            lineHeight: '90px',
            pt: 5,
            textAlign: 'center',
          }}
        >
          {product.rate}
        </Text>
        <Text
          sx={{
            display: 'block',
            fontWeight: '600',
            fontSize: 2,
            color: 'primary',
            textAlign: 'center',
          }}
        >
          {product.return}
        </Text>
        <Text
          sx={{
            display: 'block',
            fontWeight: '700',
            fontSize: 2,
            color: 'darkNeutral',
            py: 5,
            textAlign: 'center',
            borderTop: '2px solid #EBF5EC',
            borderBottom: '2px solid #EBF5EC',
            mt: 5
          }}
        >
          {product.term}
        </Text>
        <Text
          sx={{
            display: 'block',
            fontWeight: '400',
            fontSize: 0,
            color: 'mediumNeutral',
            textAlign: 'center',
            py: 5
          }}
        >
          {product.description}
        </Text>
      </Box>
    </Box>
  );
};

export default Card;
