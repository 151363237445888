/** @jsx jsx */
import { jsx, Box, Button, Flex, Input } from 'theme-ui';
import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import ArrowIcon from './icons/arrow.svg';
import SearchIcon from './icons/search.svg';
import ClearIcon from './icons/clear.svg';
import { SelectorOption } from 'components/types';

interface SelectorProps {
  options: SelectorOption[];
  onChange: (selectedItem: SelectorOption) => void;
  currentValue: SelectorOption | undefined;
  label: string | ReactNode;
  sx: object;
  textAlign?: string;
  hasSearch?: boolean;
}

const Selector: FC<SelectorProps> = ({
  options,
  onChange,
  currentValue,
  label,
  sx,
  textAlign,
  hasSearch,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<SelectorOption[]>([]);
  const [open, setOpen] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [searchText, setSearchText] = useState('');
  const containerNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const onChangeDropDown = (option: SelectorOption) => () => {
    setOpen(false);
    onChange(option);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (containerNode?.current?.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const handleSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
    e.currentTarget.value.length > 0
      ? setShowClearIcon(true)
      : setShowClearIcon(false);
    const filteredList = options.filter((option) => {
      return option.searchText
        ?.toLowerCase()
        .includes(e.currentTarget.value.toLowerCase());
    });

    setFilteredOptions(filteredList);
  };

  const onClearClick = () => {
    setSearchText('');
    setFilteredOptions(options);
    setShowClearIcon(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <Flex
      ref={containerNode}
      sx={Object.assign(
        {
          alignItems: 'center',
          position: 'relative',
        },
        sx,
      )}
    >
      <Flex
        sx={{
          px: 5,
          py: '13px',
          minWidth: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          border: '1px solid rgba(40, 39, 37, 0.1)',
          borderRadius: 3,
          mr: '0',
        }}
        onClick={() => setOpen((val) => !val)}
      >
        <Box
          as="span"
          sx={{
            color:
              label === currentValue?.label
                ? 'darkNeutral'
                : 'rgba(40, 39, 37, 0.5)',
            fontSize: 2,
            fontWeight: '700',
            lineHeight: '22px',
          }}
        >
          {label}
        </Box>
        <Button
          sx={{
            padding: 0,
            border: 'none',
            background: 'none',
            ml: 1,
            height: '12px',
            '&:focus': { outline: 0 },
          }}
        >
          <ArrowIcon
            sx={{
              ml: 2,
              transform: open ? 'rotate(180deg)' : 'none',
            }}
          />
        </Button>
      </Flex>
      <Flex
        sx={{
          display: open ? 'flex' : 'none',
          position: 'absolute',
          flexDirection: 'column',
          top: ['58px'],
          right: 'null',
          maxHeight: '300px',
          overflowY: 'auto',
          left: '0',
          p: 0,
          m: 0,
          listStyle: 'none',
          backgroundColor: 'primaryBackground',
          border: '1px solid rgba(40, 39, 37, 0.1)',
          borderRadius: 3,
          width: '100%',
          zIndex: 1,
          '& > li': {
            borderBottom: '1px solid',
            borderBottomColor: 'secondaryBackground',
            ':last-child': {
              borderBottom: 'none',
            },
          },
        }}
      >
        {hasSearch && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid rgba(40, 39, 37, 0.1)',
              margin: 0,
            }}
          >
            <Input
              value={searchText}
              onChange={handleSearchTextChange}
              placeholder="Search"
              sx={{
                flex: 1,
                fontFamily: 'Open Sans',
                paddingTop: '13px',
                paddingRight: '16px',
                paddingBottom: '13px',
                paddingLeft: '47px',
                margin: 0,
                outline: '0',
                borderRadius: '8px',
                borderColor: 'transparent',
                '&:focus': { outline: 0 },
                '&::placeholder': {
                  color: 'rgba(40, 39, 37, 0.3)',
                  lineHeight: '22px',
                },
                color: 'rgba(40, 39, 37, 1)',
                fontWeight: '600',
                fontSize: 2,
                lineHeight: '22px',
              }}
            />
            <SearchIcon
              sx={{
                position: 'absolute',
                top: '16px',
                left: '16px',
                bottom: '16px',
              }}
            />
            <ClearIcon
              onClick={onClearClick}
              sx={{
                display: showClearIcon ? 'default' : 'none',
                position: 'absolute',
                top: '14px',
                right: '13px',
                bottom: '14px',
                '&:hover': {
                  cursor: 'pointer',
                  opacity: 0.87,
                },
              }}
            />
          </Box>
        )}
        {filteredOptions.map((option, index) => (
          <Button
            key={option.value + index}
            onClick={onChangeDropDown(option)}
            type="button"
            sx={{
              justifyContent: textAlign ? textAlign : 'center',
              variant: 'navigation.listItem',
              display: 'flex',
              position: 'relative',
              listStyle: 'none',
              paddingX: 5,
              paddingY: '13px',
              width: '100%',
              background: 'transparent',
              borderColor: 'secondaryBackground',
              borderStyle: 'solid',
              borderRadius: '0px',
              color:
                currentValue && option.value === currentValue.value
                  ? 'primary'
                  : 'mediumNeutral',
              fontSize: 2,
              fontWeight: option.value === currentValue?.value ? 700 : 400,
              cursor: 'pointer',
              borderTop: '1px solid rgba(40, 39, 37, 0.1)',
              '&:hover': {
                color: 'primary',
                backgroundColor: 'secondary',
              },
              ':first-of-type': {
                borderRadius: '4px 4px 0px 0px',
                borderTop: '0',
              },
              ':last-of-type': {
                borderRadius: '0px 0px 4px 4px',
              },
            }}
          >
            {option.label}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default Selector;
