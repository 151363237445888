import React from 'react';
import { useIntl } from 'context/IntlContext';
import { Box, Text } from 'theme-ui';
import Calculator from './Calculator';
import Cards from './Cards';

const WhatDoWeOffer = () => {
  const { t } = useIntl();

  const cardList = [
    {
      productName: t('homepage.offer.product_boxes.base.product_name'),
      rate: t('homepage.offer.product_boxes.base.rate'),
      return: t('homepage.offer.product_boxes.base.return'),
      term: t('homepage.offer.product_boxes.base.term'),
      description: t('homepage.offer.product_boxes.base.description'),
    },
    {
      productName: t('homepage.offer.product_boxes.one.product_name'),
      rate: t('homepage.offer.product_boxes.one.rate'),
      return: t('homepage.offer.product_boxes.one.return'),
      term: t('homepage.offer.product_boxes.one.term'),
      description: t('homepage.offer.product_boxes.one.description'),
    },
    {
      productName: t('homepage.offer.product_boxes.market.product_name'),
      rate: t('homepage.offer.product_boxes.market.rate'),
      return: t('homepage.offer.product_boxes.market.return'),
      term: t('homepage.offer.product_boxes.market.term'),
      description: t('homepage.offer.product_boxes.market.description'),
    },
  ];

  return (
    <Box
      sx={{
        pt: [9, 9, 9, 9, 14],
        backgroundColor: 'secondaryBackground',
        textAlign: ['initial', 'initial', 'initial', 'initial', 'center'],
        pb: ['40px', '40px', '40px', '40px', '80px'],
        width: '100%',
      }}
    >
      <Box sx={{
        p: '0',
        m: 'auto',
        width: '100%',
        maxWidth: '1024px'
      }}>
        <Text
          mb={8}
          sx={{ display: 'block', p: '0px', px: 7, textAlign: ['start', 'start', 'start', 'start', 'center'] }}
          variant="sectionTitle"
        >
          {t('homepage.offer.title')}
        </Text>
        <Text
          mb={['32px', '32px', '32px', '32px', '74px']}
          sx={{
            display: 'block',
            p: '0px',
            px: 7,
            textAlign: ['start', 'start', 'start', 'start', 'center'],
            color: 'mediumNeutral',
            fontWeight: '400',
            fontSize: 4,
          }}
        >
          {t('homepage.offer.subtitle')}
        </Text>
        <Cards products={cardList} />
        <Text
          id="calculate"
          sx={{
            display: 'block',
            mt: ['40px', '40px', '40px', '40px', '80px'],
            color: 'mediumNeutral',
            fontSize: 5,
            fontWeight: '700',
            pb: [5, 5, 5, 5, 2],
            px: [7, 7, 7, 7, '0px'],
          }}
        >
          {t('homepage.offer.calculator.title')}
        </Text>
        <Text
          sx={{
            display: 'block',
            color: 'mediumNeutral',
            fontSize: 2,
            mb: [12, 12, 12, 12, '-36px'],
            px: [7, 7, 7, 7, '0px'],
          }}
        >
          {t('homepage.offer.calculator.subtitle')}
        </Text>
        <Calculator deposit={5500} timeMaxYears={30} />
        <Text
          sx={{
            display: 'block',
            mt: ['40px', '40px', '40px', '40px', '120px'],
            color: 'mediumNeutral',
            fontSize: 5,
            fontWeight: '700',
            px: [7, 7, 7, 7, '0px'],
          }}
        >
          {t('homepage.offer.stats.title')}
        </Text>
        <Box
          sx={{
            mt: [7, 7, 7, 7, 11],
            display: 'flex',
            justifyContent: 'center',
            flexDirection: ['column', 'column', 'column', 'column', 'row'],
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              borderRightWidth: ['0px', '0px', '0px', '0px', '1px'],
              borderRightColor: 'rgba(40, 39, 37, 0.1)',
              borderRightStyle: 'solid',
              px: [2, 2, 2, 2, null],
            }}
          >
            <Box
              sx={{
                px: 5,
                py: 5,
                minWidth: [null, null, null, null, '256px'],
                flex: ['1', '1', '1', '1', null],
                borderRightWidth: ['0px', '0px', '0px', '0px', '1px'],
                borderRightColor: 'rgba(40, 39, 37, 0.1)',
                borderRightStyle: 'solid',
              }}
            >
              <Text
                sx={{
                  display: 'block',
                  color: 'mediumNeutral',
                  fontWeight: '400',
                  fontSize: 0,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_1.description')}
              </Text>
              <Text
                sx={{
                  display: 'block',
                  color: 'primary',
                  fontWeight: '700',
                  fontSize: 5,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_1.value')}
              </Text>
            </Box>
            <Box
              sx={{
                px: 5,
                py: 5,
                minWidth: [null, null, null, null, '256px'],
                flex: ['1', '1', '1', '1', null],
              }}
            >
              <Text
                sx={{
                  display: 'block',
                  color: 'mediumNeutral',
                  fontWeight: '400',
                  fontSize: 0,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_2.description')}
              </Text>
              <Text
                sx={{
                  display: 'block',
                  color: 'primary',
                  fontWeight: '700',
                  fontSize: 5,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_2.value')}
              </Text>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              px: [2, 2, 2, 2, null],
            }}
          >
            <Box
              sx={{
                px: 5,
                py: 5,
                minWidth: [null, null, null, null, '256px'],
                flex: ['1', '1', '1', '1', null],
                borderRightWidth: ['0px', '0px', '0px', '0px', '1px'],
                borderRightColor: 'rgba(40, 39, 37, 0.1)',
                borderRightStyle: 'solid',
              }}
            >
              <Text
                sx={{
                  display: 'block',
                  color: 'mediumNeutral',
                  fontWeight: '400',
                  fontSize: 0,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_3.description')}
              </Text>
              <Text
                sx={{
                  display: 'block',
                  color: 'primary',
                  fontWeight: '700',
                  fontSize: 5,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_3.value')}
              </Text>
            </Box>
            <Box
              sx={{
                px: 5,
                py: 5,
                minWidth: [null, null, null, null, '256px'],
                flex: ['1', '1', '1', '1', null],
              }}
            >
              <Text
                sx={{
                  display: 'block',
                  color: 'mediumNeutral',
                  fontWeight: '400',
                  fontSize: 0,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_4.description')}
              </Text>
              <Text
                sx={{
                  display: 'block',
                  color: 'primary',
                  fontWeight: '700',
                  fontSize: 5,
                  textAlign: ['start', 'start', 'start', 'start', 'center'],
                }}
              >
                {t('homepage.offer.stats.main_stats.column_4.value')}
              </Text>
            </Box>
          </Box>
        </Box>
        <Text
          sx={{
            display: 'block',
            fontWeight: '400',
            color: 'mediumNeutral',
            fontSize: 2,
            px: 7,
            textAlign: ['start', 'start', 'start', 'start', 'center'],
            mt: [8, 8, 8, 8, 11],
          }}
        >
          {t('homepage.offer.stats.main_stats.disclaimer')}
        </Text>
      </Box>
    </Box>
  );
};

export default WhatDoWeOffer;
