import React, { FC } from 'react';
import { Box, Text, Image } from 'theme-ui';

type Props = {
  children?: any;
  borderColor?: string;
  image?: any;
  title?: string;
  description?: string;
  index: number;
};

const BorderedBox: FC<Props> = ({
  children,
  borderColor,
  image,
  title,
  description,
  index
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: ['row', 'row', 'row', 'row', 'column'],
        border: ['0', '0', '0', '0', '2px solid #ebf5ec'],
        borderRadius: '8px',
        padding: ['0', '0', '0', '0', '32px'],
        alignItems: 'flex-start',
        maxWidth: [null, null, null, null, '495px'],
        ml: (index === 1 || index === 3) ? '0px' : ['0px', '0px', '0px', '0px', 5],
        mr: (index === 2 || index === 4) ? '0px' : ['0px', '0px', '0px', '0px', 5],
        mt: (index === 3 || index === 4) ? [11, 11, 11, 11, '32px'] : index === 2 ? [11, 11, 11, 11, '0px'] : '0px'
      }}>
      {Image && (
        <Image
          src={image}
          alt={title}
          sx={{
            width: ['76px', '76px', '76px', '76px', '100px'],
            height: ['76px', '76px', '76px', '76px', '100px'],
            margin: ['0', '0', '0', '0', 'auto'],
            background: 'radial-gradient(#ebf5ec 67%, transparent 33%)',
            padding: ['12px', '12px', '12px', '12px', '22px'],
          }}
        />
      )}
      <Box sx={{ flex: '1' }}>
        {title && (
          <Text
            variant="contentTitle"
            sx={{
              display: 'block',
              pt: [null, null, null, null, 8],
              pl: [5, 5, 5, 5, null],
              textAlign: ['start', 'start', 'start', 'start', 'center'],
            }}
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            variant="underContent"
            sx={{
              display: 'block',
              pt: [7, 7, 7, 7, 5],
              pl: [5, 5, 5, 5, null],
              textAlign: ['start', 'start', 'start', 'start', 'center'],
            }}
          >
            {description}
          </Text>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default BorderedBox;
