import React from 'react';
import { Box, Container } from 'theme-ui';

import Card from './Card';

type Props = {
  products: object[];
};

const Cards: React.FC<Props> = ({ products }) => {
  return (
    <Box>
      <Box>
        <Container
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            px: '24px !important',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            alignItems: 'center',
            justifyContent: ['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center'],
          }}
        >
          {products.map((item, index) => {
            return <Card key={index} product={item} index={index} totalProducts={products.length} />;
          })}
        </Container>
      </Box>
    </Box>
  );
};

export default Cards;
