import React, { FC } from 'react';
import { Container, Box } from 'theme-ui';

const Section: FC = ({ children, outterSX, innerSX, id, ...props }) => {
  return (
    <Box
      as="section"
      id={id}
      sx={{
        ...outterSX,
      }}
    >
      <Container
        {...props}
      >
        <Box sx={{ pt: [10, 10, 10, 14], ...innerSX }}>
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default Section;
