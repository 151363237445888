import React, { useEffect } from 'react';
import { useColorMode } from 'theme-ui';

import { useIntl } from 'context/IntlContext';
import TopCTA from '../../components/TopCTA';
import WantToLearnMore from './WantToLearnMore';
import HowItWorks from './HowItWorks';
import Borrowers from './Borrowers';
import Section from '../../components/Section';
import Seo from '../../components/SEO';

import TopBannerBackground from '../../images/top-banner.png';
import WhatDoWeOffer from './WhatDoWeOffer';

const PAGE_COLOR_MODE = 'default';

const Home = () => {
  const { t } = useIntl();
  const [colorMode, setColorMode] = useColorMode();

  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
  }, [colorMode, setColorMode]);
  return (
    <>
      <Seo
        title={t('global.meta.title')}
        description={t('global.meta.description')}
      />
      <TopCTA
        heading={t('homepage.atf.title')}
        subtitle={t('homepage.atf.subtitle')}
        image={TopBannerBackground}
      />
      <Section id="how_it_works" sx={{ p: '0px !important' }}>
        <HowItWorks />
      </Section>
      <Section id="investments" sx={{ p: '0px !important' }}>
        <WhatDoWeOffer />
      </Section>
      <Section id="borrowers" sx={{ p: '0px !important' }}>
        <Borrowers />
      </Section>
      <WantToLearnMore />
    </>
  );
};

export default Home;
