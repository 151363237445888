import React, { FC, ReactElement } from 'react';

import { Box, Text, Heading, Button } from 'theme-ui';
import Link from 'components/Link';
import { useIntl } from 'context/IntlContext';
import { generateRandomUID } from 'utils';

interface TopCTAProps {
  image: ReactElement;
  heading: string;
  subtitle: string;
}

const TopCTA: FC<TopCTAProps> = ({ image, heading, subtitle, children }) => {
  const { t } = useIntl();

  const signupLink: string = `${t(
    'app.signup.link',
  )}?session=${generateRandomUID()}`;

  return (
    <Box
      as="section"
      sx={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: [
          'right top',
          'right top',
          'right top',
          'right top',
          'center',
        ],
        backgroundSize: 'cover',
        width: '100%',
        minHeight: ['0', '0', '0', '0', 'calc(100vh - 82px)'],
        margin: 'auto',
        height: 'auto',
      }}
    >
      <Box
        sx={{
          background: [
            'rgba(68, 176, 79, 0.95)',
            'rgba(68, 176, 79, 0.95)',
            'rgba(68, 176, 79, 0.95)',
            'rgba(68, 176, 79, 0.95)',
            'linear-gradient(90deg,rgba(68, 176, 79, 0.95) 50%, rgba(0, 0, 0, 0) 50%)',
          ],
          height: '100%',
          minHeight: ['0', '0', '0', '0', 'calc(100vh - 82px)'],
        }}
      >
        <Box sx={{ maxWidth: '1064px', margin: 'auto', px: 0 }}>
          <Box
            sx={{
              width: '50%',
              maxWidth: '512px',
              pt: '102px',
              pb: '178px',
              pl: '0px',
              pr: '78px',
              m: 0,
              '@media screen and (max-width: 1023px)': {
                width: '100%',
                pt: '40px',
                pb: '40px',
                pl: '24px',
                pr: '24px',
              },
            }}
          >
            <Box
              sx={{
                m: 0,
              }}
            >
              <Heading
                as="h1"
                sx={{
                  variant: 'text.heroTitle',
                  color: 'primaryBackground',
                  fontSize: [
                    '36px !important',
                    '36px !important',
                    '36px !important',
                    '36px !important',
                    '64px !important',
                  ],
                  lineHeight: ['52px', '52px', '52px', '52px', '80px'],
                  mb: 32,
                  pt: 0,
                  pb: 0,
                }}
              >
                {heading}
              </Heading>
              <Text
                as="p"
                variant="underHero"
                sx={{
                  display: 'block',
                  color: 'primaryBackground',
                  fontSize: '20px !important',
                  lineHeight: '36px',
                  mb: '44px',
                }}
              >
                {subtitle}
              </Text>
              <Button
                variant="secondary"
                as={Link}
                to={signupLink}
                sx={{
                  flexShrink: 0,
                  width: ['100%', '100%', '100%', '100%', 'auto'],
                }}
              >
                {t('homepage.atf.cta')}
              </Button>
            </Box>
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default TopCTA;
