import React, { useState } from 'react';

import { Box, Text, Flex, Button } from 'theme-ui';
import LoanModal from 'components/Modals/LoanModal';
import BorderedBox from './BorderedBox';
import { useIntl } from 'context/IntlContext';

import WorkingCapital from './icons/working-capital.png';
import Refinancing from './icons/refinancing.png';
import PurchasingInventoryEquipment from './icons/purchasing-inventory-equipment.png';
import OtherNeeds from './icons/other-needs.png';

const Borrowers = () => {
  const { t } = useIntl();
  const [loanModalIsOpen, setLoanModalIsOpen] = useState(false);

  const openLoanModal = () => {
    setLoanModalIsOpen(true);
  };

  const closeLoanModal = () => {
    setLoanModalIsOpen(false);
  };

  return (
    <Box sx={{ px: 7 }}>
      <Text
        variant="sectionTitle"
        sx={{
          display: 'block',
          textAlign: ['start', 'start', 'start', 'start', 'center'],
        }}
      >
        {t('homepage.borrowers_section.title')}
      </Text>
      <Text
        variant="sectionSubtitle"
        sx={{
          display: 'block',
          textAlign: ['start', 'start', 'start', 'start', 'center'],
        }}
      >
        {t('homepage.borrowers_section.subtitle')}
      </Text>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'column', 'column', 'row'],
          alignItems: 'stretch',
          justifyContent: 'center',
        }}
      >
        <BorderedBox
          index={1}
          image={WorkingCapital}
          title={t('homepage.borrowers_section.loans.loan_type_1.title')}
          description={t(
            'homepage.borrowers_section.loans.loan_type_1.description',
          )}
        />
        <BorderedBox
          index={2}
          image={Refinancing}
          title={t('homepage.borrowers_section.loans.loan_type_2.title')}
          description={t(
            'homepage.borrowers_section.loans.loan_type_2.description',
          )}
        />
      </Flex>
      <Flex
        sx={{
          marginBottom: ['48px', '48px', '48px', '48px', '72px'],
          flexDirection: ['column', 'column', 'column', 'column', 'row'],
          alignItems: 'stretch',
          justifyContent: 'center',
        }}
      >
        <BorderedBox
          index={3}
          image={PurchasingInventoryEquipment}
          title={t('homepage.borrowers_section.loans.loan_type_3.title')}
          description={t(
            'homepage.borrowers_section.loans.loan_type_3.description',
          )}
        />
        <BorderedBox
          index={4}
          image={OtherNeeds}
          title={t('homepage.borrowers_section.loans.loan_type_4.title')}
          description={t(
            'homepage.borrowers_section.loans.loan_type_4.description',
          )}
        />
      </Flex>
      <Text
        sx={{
          color: 'mediumNeutral',
          fontWeight: '700',
          lineHeight: '36px',
          fontSize: 5,
          mb: 2,
          display: 'block',
          textAlign: ['start', 'start', 'start', 'start', 'center'],
        }}
      >
        {t('homepage.borrowers_section.cta.title')}
      </Text>
      <Text
        sx={{
          color: 'mediumNeutral',
          fontWeight: '400',
          lineHeight: '26px',
          fontSize: 2,
          display: 'block',
          mb: [9, 9, 9, 9, 12],
          textAlign: ['start', 'start', 'start', 'start', 'center'],
        }}
      >
        {t('homepage.borrowers_section.cta.subtitle')}
      </Text>
      <Flex
        sx={{
          marginBottom: ['40px', '40px', '40px', '40px', '80px'],
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            flexShrink: 0,
            width: ['100%', '100%', '100%', '100%', 'auto'],
            maxHeight: '48px',
            mr: ['0', '0', '0', '0', 2],
            mt: [2, 2, 2, 2, '0'],
          }}
          variant="primary"
          onClick={openLoanModal}
        >
          {t('homepage.borrowers_section.cta.button')}
        </Button>
      </Flex>
      <LoanModal closeModal={closeLoanModal} modalIsOpen={loanModalIsOpen} />
    </Box>
  );
};

export default Borrowers;
