import React, { FC } from 'react';

import { Box, Text, Button, Image } from 'theme-ui';
import Link from 'components/Link';
import BannerBackground from './images/banner-background.png';
import { useIntl } from 'context/IntlContext';
import { generateRandomUID } from 'utils';

const WantToLearnMore: FC = () => {
  const { t } = useIntl();

  const signupLink: string = `${t('app.signup.link')}&session=${generateRandomUID()}`;

  return (
    <Box
      as="section"
      sx={{
        minHeight: '40rem',
        maxWidth: '100%',
        margin: 'auto',
        height: 'auto',
        display: 'flex',
        p: '0',
        flexDirection: ['column', 'column', 'column', 'column', 'row'],
        alignItems: 'stretch',
      }}
    >
      <Box
        sx={{
          flex: '1',
          maxWidth: '100%',
          width: '100%',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${BannerBackground})`,
          backgroundSize: 'cover',
        }}
      >
        <Image
          src={BannerBackground}
          alt={t('homepage.cta.title')}
          sx={{
            display: ['block', 'block', 'block', 'block', 'none'],
            width: 'inherit',
            height: 'auto',
            margin: '0 auto',
          }}
        />
      </Box>
      <Box
        sx={{
          flex: '1',
          background: 'rgba(68, 176, 79, 0.95)',
          px: [7, 7, 7, 7, 13],
          maxWidth: '100%',
        }}
      >
        <Box sx={{maxWidth: '512px'}}>
          <Text
            as="h1"
            sx={{
              display: 'block',
              variant: 'text.heroTitle',
              color: 'primaryBackground',
              fontSize: ['36px', '36px', '36px', '36px', '48px'],
              lineHeight: ['52px', '52px', '52px', '52px', '60px'],
              pt: [9, 9, 9, 14, 14],
              pb: '0',
            }}
          >
            {t('homepage.cta.title')}
          </Text>
          <Text
            as="p"
            sx={{
              display: 'block',
              color: 'primaryBackground',
              fontSize: ['18px', '18px', '18px', '18px', '20px'],
              lineHeight: ['30px', '30px', '30px', '30px', '36px'],
              pt: [5, 5, 5, 8, 8],
            }}
          >
            {t('homepage.cta.subtitle')}
          </Text>
          <Text
            as="p"
            sx={{
              display: 'block',
              color: 'primaryBackground',
              fontSize: '24px',
              lineHeight: '36px',
              fontWeight: '600',
              pt: 8,
              pb: 8,
            }}
          >
            {t('homepage.cta.punchline')}
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexDirection: [
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row',
              ],
              pb: [9, 9, 9, 14, 14],
            }}
          >
            <Button
              variant="secondary"
              as={Link}
              to={signupLink}
              sx={{
                flexShrink: 0,
                width: ['100%', '100%', '100%', '100%', 'auto'],
                maxHeight: '44px',
                mr: ['0', '0', '0', '0', 2],
                mt: [2, 2, 2, 2, '0'],
              }}
            >
              {t('homepage.cta.register')}
            </Button>
            <Button
              as={Link}
              to={'#footer'}
              sx={{
                backgroundColor: 'primary',
                borderColor: 'primaryBackground',
                borderWidth: '2px',
                borderStyle: 'solid',
                flexShrink: 0,
                width: ['100%', '100%', '100%', '100%', 'auto'],
                maxHeight: '44px',
              }}
            >
              {t('homepage.cta.contact')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WantToLearnMore;
