import React, { useState } from 'react';
import { Box, Input, Label, Text } from 'theme-ui';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  placeholder?: string;
  sx?: object;
  error?: string;
  onTextChange: (text: string) => void;
}

const TextInput: React.FC<InputProps> = ({
  id,
  label,
  placeholder,
  sx,
  error,
  onTextChange,
  ...rest
}: InputProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  function handleTextChange(e: any) {
    const text = e.target.value;

    setValue(text);

    if (text !== '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    onTextChange(text);
  }

  function handleOnFocus() {
    setIsFocused(true);
  }

  function handleOnBlur() {
    setIsFocused(false);
  }

  return (
    <Box
      sx={Object.assign(
        {
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          '&:focus-within Label': {
            transform: 'translate(16px, -8px) scale(0.75)',
            fontWeight: '400',
          },
        },
        sx,
      )}
    >
      <Input
        id={id}
        onChange={handleTextChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        sx={{
          width: '100%',
          paddingTop: '13px',
          paddingRight: '16px',
          paddingBottom: '13px',
          paddingLeft: '16px',
          outline: '0',
          background: '#fff',
          borderRadius: '8px',
          borderColor: error ? 'error' : 'rgba(40, 39, 37, 0.1)',
          '&:focus': { outline: 0 },
          color: 'rgba(40, 39, 37, 1)',
          fontWeight: '700',
          fontSize: 2,
          lineHeight: '22px',
        }}
      />
      <Label
        sx={{
          width: 'max-content',
          background: '#FFFFFF',
          fontSize: 2,
          lineHeight: '22px',
          fontWeight: isActive ? '400' : '700',
          paddingTop: '0',
          paddingBottom: '0',
          paddingRight: '4px',
          paddingLeft: '4px',
          color: error ? 'error' : 'rgba(40, 39, 37, 0.5)',
          pointerEvents: 'none',
          position: 'absolute',
          transform: isActive
            ? 'translate(16px, -8px) scale(0.75)'
            : 'translate(16px, 13px) scale(1)',
          transformOrigin: 'top left',
          transition: 'all 0.2s ease-out',
        }}
        htmlFor={id}
      >
        {isFocused || isActive ? label : placeholder ? placeholder : label}
      </Label>
      {error && (
        <Text
          sx={{
            fontSize: '11px',
            fontWeight: '400',
            color: 'error',
            lineHeight: '16px',
            marginTop: 2,
          }}
        >
          {error}
        </Text>
      )}
    </Box>
  );
};

export default TextInput;
