import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'context/IntlContext';

const SEO_QUERY = graphql`
  query DefaultSEOQueryElroy {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

function SEO({ description, lang, meta, keywords, title }) {
  const { locale, t } = useIntl();
  const data = useStaticQuery(SEO_QUERY);

  const metaDescription = description
    || t('aboveTheFold.title.personal', {
      defaultMessage: data.site.siteMetadata.description,
    });

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || locale,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // {
        //   name: `apple-itunes-app`,
        //   content: `app-id=1565004653`,
        // },
        // {
        //   name: `google-play-app`,
        //   content: `app-id=com.neowintech.gazoo`,
        // },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : [],
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  keywords: [],
  description: '',
  lang: 'en',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
