import React from 'react';

import { Box, Text, Flex } from 'theme-ui';
import BorderedBox from './BorderedBox';
import { useIntl } from 'context/IntlContext';

import Credit from './icons/credit.png';
import Investment from './icons/investment.png';
import Return from './icons/return.png';

const HowItWorks = () => {
  const { t } = useIntl();

  return (
    <Box sx={{px: 7, maxWidth: '1024px', m: 'auto'}}>
      <Text
        variant="sectionTitle"
        sx={{
          display: 'block',
          textAlign: ['start', 'start', 'start', 'start', 'center'],
        }}
      >
        {t('homepage.how_it_works.title')}
      </Text>
      <Text
        variant="sectionSubtitle"
        sx={{
          display: 'block',
          textAlign: ['start', 'start', 'start', 'start', 'center'],
        }}
      >
        {t('homepage.how_it_works.subtitle')}
      </Text>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'column', 'column', 'row'],
          alignItems: 'stretch',
          justifyContent: 'center',
        }}
      >
        <BorderedBox
          index={1}
          image={Investment}
          title={t('homepage.how_it_works.investment.title')}
          description={t('homepage.how_it_works.investment.subtitle')}
        />
        <BorderedBox
          index={2}
          image={Credit}
          title={t('homepage.how_it_works.credit.title')}
          description={t('homepage.how_it_works.credit.subtitle')}
        />
        <BorderedBox
          index={3}
          image={Return}
          title={t('homepage.how_it_works.return.title')}
          description={t('homepage.how_it_works.return.subtitle')}
        />
      </Flex>
    </Box>
  );
};

export default HowItWorks;
