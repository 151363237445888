import React, { useState, useContext } from 'react';
import BaseModal from '../BaseModal';
import { Box, Text, Button, Image } from 'theme-ui';
import TextInput from '../../TextInput';
import Selector from '../../Selector';
import SpinnerIcon from '../../Spinner';
import CloseIcon from '../icons/close.png';
import CheckIcon from './icons/check.svg';
import ViewerContext from '../../../context/ViewerContext';
import { useEffect } from 'react';
import { SelectorOption } from 'components/types';
import { useIntl } from '../../../context/IntlContext';
import { requestLoan } from 'api';
import { EMAIL_REGEX } from 'utils';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Toast from 'components/Toast';

interface LoanModalProps {
  closeModal: () => void;
  afterOpen?: () => void;
  afterClose?: () => void;
  modalIsOpen: boolean;
  children?: any;
}

const LoanModal = ({ closeModal, modalIsOpen }: LoanModalProps) => {
  const { t } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [isReadyToSend, setIsReadyToSend] = useState(false);
  const [countryCode, setCountryCode] = useState<SelectorOption>();
  const [countryCodeList, setCountryCodeList] = useState<SelectorOption[]>([]);
  const [term, setTerm] = useState<SelectorOption>();
  const [loanAmount, setLoanAmount] = useState<SelectorOption>();
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const loanAmountOptions: SelectorOption[] = [
    { label: t('loan_form.modal.amount.option_1'), value: 'LA_1K_TO_10K' },
    { label: t('loan_form.modal.amount.option_2'), value: 'LA_10001_TO_50K' },
    { label: t('loan_form.modal.amount.option_3'), value: 'LA_50001_TO_100K' },
    { label: t('loan_form.modal.amount.option_4'), value: 'LA_100001_TO_250K' },
    { label: t('loan_form.modal.amount.option_5'), value: 'LA_MORE_THAN_250K' },
  ];

  const termOptions: SelectorOption[] = [
    { label: t('loan_form.modal.term.option_0'), value: 'LT_UP_TO_1_YEAR' },
    { label: t('loan_form.modal.term.option_1'), value: 'LT_1_TO_3_YEARS' },
    { label: t('loan_form.modal.term.option_2'), value: 'LT_3_TO_5_YEARS' },
    {
      label: t('loan_form.modal.term.option_3'),
      value: 'LT_MORE_THAN_5_YEARS',
    },
  ];

  const { countriesList } = useContext(ViewerContext);
  useEffect(() => {
    checkFormStatus();
  }, [countryCode, term, companyName, email, phoneNumber, loanAmount]);

  useEffect(() => {
    const list = getCountryCodesList();
    setCountryCodeList(list);
  }, [countriesList]);

  function handleCloseModal() {
    setIsSent(false);
    resetFields();
    closeModal();
  }

  function handleCompanyNameChange(text: string) {
    setCompanyName(text);
  }

  function handleEmailChange(text: string) {
    setEmail(text);
  }

  function handlePhoneNumberChange(text: string) {
    setPhoneNumber(text);
  }

  function onCountryCodeChange(code: SelectorOption) {
    setCountryCode(code);
  }

  function onTermChange(term: SelectorOption) {
    setTerm(term);
  }

  function onLoanAmountChange(loanAmount: SelectorOption) {
    setLoanAmount(loanAmount);
  }

  function resetFields() {
    setCountryCode(undefined);
    setTerm(undefined);
    setLoanAmount(undefined);
    setCompanyName('');
    setEmail('');
    setPhoneNumber('');
  }

  function checkFormStatus() {
    if (!isEmailValid() || !isPhoneNumberValid() || hasEmptyFields()) {
      setIsReadyToSend(false);
      return;
    }

    setIsReadyToSend(true);
  }

  function isEmailValid() {
    const isValid = !!email.match(EMAIL_REGEX);
    setInvalidEmail(!isValid);
    return isValid;
  }

  function isPhoneNumberValid() {
    const result = parsePhoneNumberFromString(
      `${countryCode?.value} ${phoneNumber}`,
    );

    const isValid = !!result && result.isValid();

    setInvalidPhoneNumber(!isValid);
    return isValid;
  }

  function hasEmptyFields() {
    if (!countryCode) return true;
    if (!term) return true;
    if (!loanAmount) return true;
    if (companyName.trim() === '') return true;
    if (email.trim() === '') return true;
    if (phoneNumber.trim() === '') return true;
    return false;
  }

  async function handleSendLoan() {
    setIsLoading(true);
    const payload: RequestLoanParams = {
      companyName: companyName,
      email: email,
      phone: {
        callingCode: countryCode?.value || '',
        phoneNumber: phoneNumber,
      },
      loanAmount: loanAmount?.value || '',
      term: term?.value || '',
    };

    const withoutError = await requestLoan(payload);
    setIsLoading(false);

    if (withoutError) {
      setIsSent(true);
      return;
    }

    setShowErrorModal(true);
  }

  function getEmojiFromUnicode(unicode: string | undefined) {
    if (!unicode) return '';
    const chars = unicode.replaceAll('U+', '').split(' ');
    return chars
      .map((char) => {
        return String.fromCodePoint(Number('0x' + char));
      })
      .join('');
  }

  function getCountryCodesList(): SelectorOption[] {
    if (!countriesList) return [];
    return countriesList.map((country) => {
      const flag = getEmojiFromUnicode(country.flagUnicode);
      return {
        label: (
          <Box>
            <Text sx={{ mr: 2 }}>{flag}</Text>
            <Text>
              {country.callingCode} - {country.countryCode}
            </Text>
          </Box>
        ),
        value: country.callingCode || '',
        searchText: `${country.callingCode} ${country.countryCode}`
      };
    });
  }

  return (
    <BaseModal closeModal={handleCloseModal} modalIsOpen={modalIsOpen}>
      {isSent ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '100px',
              height: '100px',
              backgroundColor: 'primary',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderRadius: '50%',
              mt: [5, 5, 5, 5, 12],
              mb: 9,
            }}
          >
            <CheckIcon />
          </Box>
          <Text
            sx={{
              color: 'mediumNeutral',
              fontWeight: '700',
              lineHeight: '36px',
              fontSize: 5,
              display: 'block',
              mb: 6,
              textAlign: 'center',
            }}
          >
            {t('loan_form_success.modal.title')}
          </Text>
          <Text
            sx={{
              fontSize: 2,
              lineHeight: '26px',
              fontWeight: '400',
              color: 'mediumNeutral',
              textAlign: 'center',
              mb: 12,
            }}
          >
            {t('loan_form_success.modal.subtitle')}
          </Text>
          <Text
            onClick={handleCloseModal}
            sx={{
              color: 'primary',
              mb: 6,
              fontSize: 2,
              lineHeight: '24px',
              fontWeight: '700',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {t('loan_form_success.modal.close_button')}
          </Text>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              pb: 6,
            }}
          >
            <Text
              sx={{
                color: 'mediumNeutral',
                fontWeight: '700',
                lineHeight: '36px',
                fontSize: 5,
                display: 'block',
                textAlign: ['start', 'start', 'start', 'start', 'center'],
              }}
            >
              {t('loan_form.modal.title')}
            </Text>
            <Button
              onClick={handleCloseModal}
              sx={{
                background: 'transparent',
                width: '40px',
                height: '40px',
                p: '13px',
              }}
            >
              <Image src={CloseIcon} />
            </Button>
          </Box>
          <Text
            sx={{
              fontSize: 2,
              lineHeight: '26px',
              fontWeight: '400',
              color: 'mediumNeutral',
              pb: 11,
            }}
          >
            {t('loan_form.modal.subtitle')}
          </Text>
          <TextInput
            id="companyName"
            label={t('loan_form.modal.company_name.placeholder')}
            onTextChange={handleCompanyNameChange}
            type={'text'}
            sx={{ mb: 7 }}
          />
          <TextInput
            id="email"
            label={t('loan_form.modal.email.placeholder')}
            onTextChange={handleEmailChange}
            type={'email'}
            sx={{ mb: 7 }}
            error={
              invalidEmail && email.length > 0
                ? t('loan_form.modal.email.invalid_format') || 'Invalid email'
                : ''
            }
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              justifyContent: 'center',
              alignItems: 'flex-start',
              mb: 7,
            }}
          >
            <Selector
              options={countryCodeList}
              onChange={onCountryCodeChange}
              currentValue={countryCode ? countryCode : countryCodeList[0]}
              hasSearch={true}
              label={
                countryCode
                  ? countryCode.label
                  : t('loan_form.modal.calling_code.placeholder')
              }
              textAlign="flex-start"
              sx={{
                mr: [0, 0, 0, 0, 5],
                flex: '1',
                flexShrink: 0,
                width: ['100%', '100%', '100%', '100%', 'auto'],
              }}
            />
            <TextInput
              sx={{
                flex: '1',
                mt: [7, 7, 7, 7, 0],
              }}
              id="phoneNumber"
              label={t('loan_form.modal.number.placeholder')}
              onTextChange={handlePhoneNumberChange}
              type={'tel'}
              error={
                invalidPhoneNumber && phoneNumber.length > 0
                  ? t('loan_form.modal.number.invalid') || 'Invalid phone number'
                  : ''
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              justifyContent: 'center',
              alignItems: 'flex-start',
              mb: 7,
            }}
          >
            <Selector
              options={loanAmountOptions}
              onChange={onLoanAmountChange}
              currentValue={loanAmount}
              textAlign="flex-start"
              label={
                loanAmount
                  ? loanAmount.label
                  : t('loan_form.modal.amount.placeholder')
              }
              sx={{
                mr: [0, 0, 0, 0, 5],
                flex: '1',
                flexShrink: 0,
                width: ['100%', '100%', '100%', '100%', 'auto'],
              }}
            />
            <Selector
              options={termOptions}
              onChange={onTermChange}
              currentValue={term}
              textAlign="flex-start"
              label={term ? term.label : t('loan_form.modal.term.placeholder')}
              sx={{
                mr: [0, 0, 0, 0, 0],
                flex: '1',
                flexShrink: 0,
                mt: [7, 7, 7, 7, 0],
                width: ['100%', '100%', '100%', '100%', 'auto'],
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              sx={{
                fontSize: 0,
                lineHeight: '22px',
                fontWeight: '400',
                color: 'mediumNeutral',
                pb: [7, 7, 7, 7, 0],
                mr: [0, 0, 0, 0, 5],
              }}
            >
              {t('loan_form.modal.disclaimer')}
            </Text>
            <Button
              disabled={!isReadyToSend}
              onClick={handleSendLoan}
              sx={{
                flexShrink: 0,
                width: ['100%', '100%', '100%', '100%', 'auto'],
                maxHeight: '44px',
                mr: ['0', '0', '0', '0', '0'],
                mt: '0',
                minWidth: '136px',
                backgroundColor: isReadyToSend ? 'primary' : 'disabledButton',
              }}
            >
              {isLoading ? (
                <SpinnerIcon width="20px" height="20px" />
              ) : (
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: '700',
                    color: 'lightNeutral',
                  }}
                >
                  {t('loan_form.modal.send_button')}
                </Text>
              )}
            </Button>
          </Box>
        </>
      )}
      <Toast
        show={showErrorModal}
        message="Something went wrong"
        type="error"
        duration={3000}
        onClose={() => setShowErrorModal(false)}
      />
    </BaseModal>
  );
};

export default LoanModal;
